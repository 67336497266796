import { Get, Post } from '../../com/thunk-base';
import { ConfirmEmailRequest } from './request/confirm-email-request';
import { EmailForgotRequest } from './request/email-forgot-request';
import { ExistsEmailRequest } from './request/exists-email-request';
import { LoginRequest } from './request/login-request';
import { RegisterRequest } from './request/register-request';
import { ResendConfirmationEmailRequest } from './request/resend-confirmation-email-request';
import { ResetPasswordRequest } from './request/reset-password-request';
import { ConfirmEmailResponse } from './response/confirm-email-response';
import { EmailForgotResponse } from './response/email-forgot-request';
import { ExistsEmailResponse } from './response/exists-email-response';
import { IsConfirmedResponse } from './response/is-confirmed-response';
import { LoginResponse } from './response/login-response';
import { RegisterResponse } from './response/register-response';
import { ResendConfirmationEmailResponse } from './response/resend-confirmation-email-response';
import { ResetPasswordResponse } from './response/reset-password-response';

const Path = (path: string) => 'Auth/' + path;

export const AuthThunk = {
    Login: Post<LoginResponse, LoginRequest>(Path('login')),
    ExistsEmail: Post<ExistsEmailResponse, ExistsEmailRequest>(Path('ExistsEmail')),
    Register: Post<RegisterResponse, RegisterRequest>(Path('Register')),
    SendEmailForgot: Post<EmailForgotResponse, EmailForgotRequest>(Path('ForgotPassword')),
    CheckIfConfirmed: Get<IsConfirmedResponse>(Path('IsConfirmed')),
    ResetPassword: Post<ResetPasswordResponse, ResetPasswordRequest>(Path('ResetPassword')),
    ResendActivation: Post<ResendConfirmationEmailResponse, ResendConfirmationEmailRequest>(Path('ResendConfirmationEmail')),
    ConfirmEmail: Post<ConfirmEmailResponse, ConfirmEmailRequest>(Path('ConfirmEmail'))
};