import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from './client';
import { UrlUtils } from '../../common/utils/url-utils';

export const Get = <TResponse, TRequest extends { [key: string]: string | number | null | undefined } = Record<string, string | number | null | undefined>>(path: string) => {
    return createAsyncThunk(path, async (rq?: TRequest) => {
        try {
            const rp = await client.get<TResponse>(path + (rq != null ? UrlUtils.ComposeQueryParams(rq) : ''));
            return rp.data as TResponse;
        } catch (error) {
            return null;
        }
    });
};

export const Post = <TResponse, TRequest>(path: string) => {
    return createAsyncThunk(path, async (rq: TRequest) => {
        try {
            const rp = await client.post<TResponse>(path, rq);
            return rp.data as TResponse;
        } catch (error) {
            return null;
        }
    });
};