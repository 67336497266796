import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { PaymentThunk } from './payment-thunk';
import { Product } from '../../payment/product';

interface PaymentState {
    subscriptions?: {
        products?: Product[],
        loading?: boolean
    }
}

const initialState: PaymentState = { };

const GetProductsBuilders = (builder: ActionReducerMapBuilder<PaymentState>) => {
    builder.addCase(PaymentThunk.GetProducts.pending, (state) => {
        state.subscriptions = {loading: true};
    });
    builder.addCase(PaymentThunk.GetProducts.fulfilled, (state, { payload }) => {
        if (payload?.success)
            state.subscriptions = {
                loading: false,
                products: payload?.products
            };
        else
            state.subscriptions = {
                loading: false
            };
    });
    builder.addCase(PaymentThunk.GetProducts.rejected, (state) => {
        state.subscriptions = { loading: false };
    });
};


const slice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        logout: () => initialState
    },
    extraReducers: (builder) => {
        GetProductsBuilders(builder);
    },
});

export const PaymentSlice = {
    slice: slice.reducer,
    actions: slice.actions,
    whitelist: [] as string[]
};