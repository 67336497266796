import './input-chat.scss';

import TextArea from 'antd/es/input/TextArea';
import { T, UseTranslation } from '../../elemental/t';
import { SendOutlined } from '@ant-design/icons';
import { A } from '../../elemental/a';
import { ProjectThunk } from '../../../data/store/project/project-thunk';
import { useAppDispatch, useAppSelector } from '../../../data/store/store';
import { useEffect, useState } from 'react';
import { SubscriptionThunk } from '../../../data/store/subscription/subscription-thunk';
import { SubscriptionUtils } from '../../../common/subscription/sub-utils';
import { Alert, Button } from 'antd';
import { Links } from '../../../data/links';

interface Props {
    disabled: boolean;
    projectKey: string;
}

export const ProjectChatInput = (props: Props) => {
    const { disabled, projectKey } = props;

    const dispatch = useAppDispatch();
    const t = UseTranslation();

    const { subscriptions } = useAppSelector(state => state.Subscriptions);

    const [text, SetText] = useState<string>('');

    const freeUser = subscriptions && SubscriptionUtils.IsFree(subscriptions);
    const noMoreQuota = subscriptions && SubscriptionUtils.RemainingQuota(subscriptions) <= 0;

    const canSend = !disabled && !freeUser && !noMoreQuota && text?.length > 0 && !subscriptions?.loading;

    useEffect(() => {
        if (subscriptions?.loading)
            return;

        if (subscriptions?.subscriptions)
            return;

        dispatch(SubscriptionThunk.Get());
    }, []);


    const HanndleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        SetText(event.currentTarget.value);
    };

    const HandleSend = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!canSend)
            return;

        dispatch(ProjectThunk.Generate({
            text: text,
            projectKey: projectKey
        }));
    };

    const ChatLine = () => {
        const SubLink = () => {
            return <A to={Links.Payment.Subscriptions}><Button type="primary"><T k='header.pricing' /></Button></A>;
        };
        if (!subscriptions?.loading && freeUser)
            return <Alert type="error" message={<><T k='video.errors.freeUsersMax' />{' '}{SubLink()}</>} />;
        if (!subscriptions?.loading && noMoreQuota)//No more quota
            return <Alert type="error" message={<><T k='video.errors.quotaLimit' />{' '}{SubLink()}</>} />;
        return <TextArea disabled={noMoreQuota || freeUser} placeholder={t('chat.input.placeholder')} maxLength={4000} onChange={HanndleOnChange} value={text}></TextArea>;
    };

    return <div className="input-chat">
        {ChatLine()}
        <A to="#" className={canSend ? '' : 'disabled'} onClick={HandleSend}>
            <SendOutlined />
        </A>
    </div>;
};