import { UseLanguageData } from '../elemental/t';

type optionsParts = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';

interface Props {
    date: string | Date;
    options?: optionsParts[];
}

export const DateFormat = (date: string | Date, language: string, options?: optionsParts[]) => {
    options ??= ['year','month','day','hour','minute'];

    const opt = {
        year: options.includes('year') ? 'numeric' : undefined,
        month: options.includes('month') ? 'long' : undefined,
        day: options.includes('day') ? 'numeric' : undefined,
        hour: options.includes('hour') ? 'numeric' : undefined,
        minute: options.includes('minute') ? 'numeric' : undefined,
        second: options.includes('second') ? 'numeric' : undefined
    } as const;

    return new Intl.DateTimeFormat(language, opt).format(new Date(date));
};

export const DateComp = (props: Props) => {
    const { date } = props;

    const language = UseLanguageData();
    return DateFormat(date, language.language, props.options);
};