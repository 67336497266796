import { combineReducers } from 'redux';
import { ProjectSlice } from './project/project-slice';
import { AuthSlice } from './auth/auth-slice';
import createFilter from 'redux-persist-transform-filter';
import { PaymentSlice } from './payment/payment-slice';
import { SubscriptionSlice } from './subscription/subscription-slice';

const all = {
    Video: ProjectSlice,
    Auth: AuthSlice,
    Payment: PaymentSlice,
    Subscriptions: SubscriptionSlice
};

export type AllReducersType = {
    [K in keyof typeof all]: ReturnType<typeof all[K]['slice']>;
};

export const allReducers = combineReducers(
    Object.fromEntries(Object.entries(all).map(([key, value]) => [key, value.slice]))
);


export const allWhitelist = Object.
    entries(all)
    .map(([key, value]) => createFilter(
        key,
        value.whitelist
    ));
