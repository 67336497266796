import { Button, Form, Input } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { T, TL } from '../../components/elemental/t';
import { useAppDispatch } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { useState } from 'react';
import { Loading } from '../../components/elemental/loading';
import { useParameters } from '../../common/utils/use-parameters';
import { ResetPasswordResponse } from '../../data/store/auth/response/reset-password-response';

export const ResetPassword = () => {

    const dispatch = useAppDispatch();
    const [userId, code] = useParameters(['userId', 'code']);

    const [password, setPassword] = useState<string>('');
    const [error, SetError] = useState<string | null>(null);
    const [step, SetStep] = useState<number>(0);

    const HandleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const HandleSubmit = async () => {
        SetStep(1);
        const rp = await dispatch(AuthThunk.ResetPassword({ userId: userId ?? '', code: code ?? '', password: password }));
        const rpp = rp.payload as ResetPasswordResponse;
        if (rpp.success) {
            SetStep(2);
            return;
        }
        if (rpp.error) {
            SetError(rpp.error);
        }
        SetStep(0);
    };

    let passwordError: string | null = null;
    if (error ?? '' != '') {
        passwordError = 'auth.register.password.error.' + error;
    }

    if ((userId ?? '') == '' || (code ?? '') == '')
        return <></>;

    return (
        <LayoutMiddle className="login">
            <FormMiddle
                onFinish={HandleSubmit}
            >
                <Form.Item>
                    <h1><T k='auth.reset.title' /></h1>
                    {step == 0 && <p><T k='auth.reset.subtitle' /></p>}
                </Form.Item>
                {step == 0 && <>
                    <Form.Item
                        validateStatus={passwordError ? 'error' : undefined}
                        help={<TL k={passwordError ?? ''} />}
                    >
                        <Input key='password' type='password' name='password' onChange={HandleChangePassword} value={password} />
                    </Form.Item>
                    <Form.Item className="two-buttons">
                        <Button type="primary" onClick={HandleSubmit}><T k='auth.reset.submit' /></Button>
                    </Form.Item>
                </>}
                {step == 1 && <Loading />}
                {step == 2 && <Form.Item><T k='auth.reset.done' /></Form.Item>}
            </FormMiddle>
        </LayoutMiddle>
    );
};