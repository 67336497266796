import { CSSProperties, HTMLAttributes } from 'react';

interface Props {
    grow?: number;
}

export const FlexItem = (
    props: Props & HTMLAttributes<HTMLDivElement>
) => {
    const { grow, style: styleProps } = props;
    const style = { ...(styleProps ?? {} as CSSProperties) };

    if (grow != null) {
        style.flexGrow = grow;
    }

    const cleanProps = { ...props, grow: undefined, style: undefined };
    return <div {...cleanProps}  style={style}/>;
};