import { TranslationOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import './language.scss';

export const Language = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const items: MenuProps['items'] = [
        {
            key: 'es',
            label: 'Español',
            onClick: () => changeLanguage('es')
        },
        {
            key: 'en',
            label: 'English',
            onClick: () => changeLanguage('en')
        }
    ];

    return (
        <Dropdown menu={{ items }} trigger={['click']} className="language-dropdown">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <TranslationOutlined />
            </a>
        </Dropdown>
    );
};