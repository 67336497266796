import { Button, Form, Input, Progress } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { T, UseTranslation } from '../../components/elemental/t';
import { useState } from 'react';

interface Props {
    onContinue: (email: string) => void,
    defaultValue?: string
}

export const RegisterUsername = (props: Props) => {
    const { onContinue, defaultValue } = props;

    const [email, setEmail] = useState<string>(defaultValue ?? '');
    const t = UseTranslation();

    const HandleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const HandleContinue = () => {
        if (!email)
            return;

        onContinue(email);
    };

    return <FormMiddle
        key='f'
        onFinish={HandleContinue}
    >
        <Form.Item>
            <h1><T k='auth.register.title' /></h1>
            <p><T k='auth.register.subtitle' /></p>
        </Form.Item>
        <Form.Item>
            <Progress key="p" showInfo={false} percent={0} />
        </Form.Item>
        <Form.Item
            label={<T k='auth.register.username.label' />}
            rules={[{ type: 'email' }]}
        >
            <Input key='email' type='email' name='email' placeholder={t('auth.login.username.placeholder')} onChange={HandleChangeEmail} value={email}/>
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" type="primary"><T k='auth.register.continue' /></Button>
        </Form.Item>
    </FormMiddle>;
};