export enum EVideoStatus {
    Unknown = 0,
    Initial = 1,
    ProcessingIA = 1000,
    CreatingVideo = 2000,
    CreatingVideoScenes = 2100,
    CreatingVideoComposing = 2200,
    Finished = 3000,
    Error = 3100, //Unknown error
    ErrorIA = 31001000,
    ErrorVideo = 31002000,
    ErrorVideoScenes = 31002100,
    ErrorVideoCompmosing = 31002200,
}