import './video-player.scss';

import { API_URL } from '../../../data/com/client';
import { UrlUtils } from '../../../common/utils/url-utils';
import { Button } from 'antd';
import { T } from '../../elemental/t';
import { A } from '../../elemental/a';

interface Props {
    pKey: string,
    position: number,
    secret: string
}

export const VideoPlayer = (props: Props) => {
    const { pKey, position, secret } = props;

    const params = UrlUtils.ComposeQueryParams({ k: pKey, p: position, s: secret });
    const src = API_URL + '/project/play' + params;
    const src_down = API_URL + '/project/download' + params;
    const src_thumb = API_URL + '/project/thumbnail' + params;

    return (
        <div className="video-player">
            {<video src={src} poster={src_thumb} controls preload="none" />}
            <A to={src_down}><Button type='primary'><T k='video.download' /></Button></A>
        </div>
    );
};