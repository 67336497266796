import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { LoginErrorEnum } from './response/login-response';
import { AuthThunk } from './auth-thunk';
import { client } from '../../com/client';
import { store } from '../store';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';


export type AuthState = {
    user?: {
        logged?: boolean,
        token?: string,
        login?: {
            loading: boolean,
            errorCode?: LoginErrorEnum
        },
        register?: {
            loading: boolean,
            error?: string
        },
        updatedOn?: Date,
        confirmed?: {
            loading: boolean,
            confirmed?: boolean,
            loaded: boolean//to avoid calling it continously
        },
        resendActivation?: {
            loading: boolean,
            lastActivationSend?: Date
        }
    }
}

const whitelist = ['user.logged', 'user.token', 'user.updatedOn', 'user.confirmed.confirmed','user.resendActivation.lastActivationSend'];

const initialState: AuthState = {};

const LoginBuilders = (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(AuthThunk.Login.pending, (state) => {
        state.user ??= {};
        state.user.login = {
            loading: true
        };
    });
    builder.addCase(AuthThunk.Login.fulfilled, (state, { payload }) => {
        state.user ??= {};
        if ((payload?.token ?? '') != '') {
            state.user.logged = true;
            state.user.updatedOn = new Date();
            state.user.token = payload?.token;
        }
        state.user.login = {
            loading: false,
            errorCode: payload?.errorCode
        };
    });
    builder.addCase(AuthThunk.Login.rejected, (state) => {
        state.user ??= {};
        state.user.logged = false;
        state.user.updatedOn = new Date();
        state.user.login = {
            loading: false,
            errorCode: LoginErrorEnum.Unknown
        };
    });
};
const RegisterBuilders = (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(AuthThunk.Register.pending, (state) => {
        state.user ??= {};
        state.user.register = {
            loading: true
        };
    });
    builder.addCase(AuthThunk.Register.fulfilled, (state, { payload }) => {
        state.user ??= {};
        if ((payload?.token ?? '') != '') {
            state.user.logged = true;
            state.user.updatedOn = new Date();
            state.user.token = payload?.token;
        }
        state.user.register = {
            loading: false,
            error: payload?.error
        };
        state.user.login = {
            loading: false
        };
    });
    builder.addCase(AuthThunk.Register.rejected, (state) => {
        state.user ??= {};
        state.user.register = {
            loading: false
        };
    });
};
const ConfirmedBuilders = (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(AuthThunk.CheckIfConfirmed.pending, (state) => {
        state.user ??= {};
        state.user.confirmed = { loading: true, loaded:false };
    });
    builder.addCase(AuthThunk.CheckIfConfirmed.fulfilled, (state, { payload }) => {
        state.user ??= {};
        state.user.confirmed = { loading: false, confirmed: payload?.success ? payload?.confirmed : undefined, loaded: true };
    });
    builder.addCase(AuthThunk.CheckIfConfirmed.rejected, (state) => {
        state.user ??= {};
        state.user.confirmed = { loading: false, confirmed: undefined, loaded: false };
    });
};
const ResendActivationBuilders = (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(AuthThunk.ResendActivation.pending, (state) => {
        state.user ??= {};
        state.user.resendActivation = { loading: true };
    });
    builder.addCase(AuthThunk.ResendActivation.fulfilled, (state, { payload }) => {
        state.user ??= {};
        state.user.resendActivation = { loading: false, lastActivationSend: payload?.success ? new Date()  : undefined};
    });
    builder.addCase(AuthThunk.ResendActivation.rejected, (state) => {
        state.user ??= {};
        state.user.resendActivation = { loading: false };
    });
};

const slice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        logout: () => initialState
    },
    extraReducers: (builder) => {
        LoginBuilders(builder);
        RegisterBuilders(builder);
        ConfirmedBuilders(builder);
        ResendActivationBuilders(builder);
    }
});


export const AuthSlice = {
    slice: slice.reducer,
    actions: slice.actions,
    whitelist: whitelist
};

//Interceptors to logout if we are not logged
client.interceptors.request.use(
    async (req: InternalAxiosRequestConfig) => {
        const state = store.getState();
        const token = state.Auth?.user?.token;

        if (token) {
            req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
    },

    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (res: AxiosResponse<unknown>) => {
        if (res.status === 401) {
            store.dispatch(AuthSlice.actions.logout());
        }
        return res;
    },
    (error) => Promise.reject(error)
);