import './price.scss';

import { ECurrency } from '../../data/payment/currency';
import { T, UseLanguageData } from '../elemental/t';
import { EBillingPeriod } from '../../data/payment/billing-period';

interface Props {
    value: number,
    currency: ECurrency,
    billing?: EBillingPeriod
}

export const Price = (props: Props) => {
    const { value, currency, billing } = props;
    const language = UseLanguageData();

    let monthValue = value;
    let showPerMonth = false;

    if (billing != null) {
        switch (billing) {
            case EBillingPeriod.Biannually:
                monthValue = value / 6;
                showPerMonth = true;
                break;
            case EBillingPeriod.Yearly:
                monthValue = value / 12;
                showPerMonth = true;
                break;
            case EBillingPeriod.Monthly:
                showPerMonth = true;
                break;
        }
    }

    const pFormatter = new Intl.NumberFormat(language.language, { style: 'currency', currency: ECurrency[currency] });
    return <div className="price">
        <span className="number">{pFormatter.format(monthValue)}</span>
        <span className="period">{showPerMonth && <T k='payments.price.perMonth'/>}</span>
    </div>;

};