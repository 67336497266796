import './header.scss';

import { Col, Flex, Menu, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { AppLogo } from './logo/logo';
import { T } from '../../elemental/t';
import { Language } from './language/language';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../data/links';
import { A } from '../../elemental/a';
import { useAppSelector } from '../../../data/store/store';
import { Confirmation } from './confirmation/confirmation';
import { MenuOutlined } from '@ant-design/icons';

export const AppHeader = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.Auth);

    const menu_items = [
        {
            key: '0',
            url: Links.Main.TikTok,
            label: 'TikTok',
        }, {
            key: '1',
            url: Links.Main.Youtube,
            label: 'Youtube',
        }, {
            key: '2',
            url: Links.Main.Instagram,
            label: 'Instagram',
        }, {
            key: '3',
            url: Links.Payment.Subscriptions,
            label: <T k='header.pricing' />,
        }
    ];

    const phone_menu_items = [
        {
            key: '0',
            icon: <MenuOutlined />,
            label: '',
            children: [
                ...menu_items,
                {
                    key: 'c',
                    url: Links.Settings,
                    label: <T k="header.settings" />
                }
            ]
        }
    ];

    const onClick = (e: { key: string }) => {
        navigate(menu_items.find(x => x.key == e.key)?.url ?? Links.Main.Default);
    };

    return (
        <Header>
            <Row>
                <Col xs={14} sm={14} md={18} lg={6} xl={6} xxl={6} className="col-logo">
                    <AppLogo/>
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12} className="col-menu">
                    <Menu
                        onClick={onClick}
                        mode="horizontal"
                        items={menu_items}
                    />
                </Col>
                <Col xs={10} sm={10} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    <Flex justify='flex-end'>
                        <div>
                            {!user?.logged && <A to={Links.Auth.Login}><T k="header.login" /></A>}
                            {user?.logged && <>
                                <A to={Links.Settings}
                                    className="configuration-header"
                                >
                                    <T k="header.settings" />
                                </A>
                                <Menu
                                    className="burger-menu"
                                    onClick={onClick}
                                    mode="horizontal"
                                    items={phone_menu_items}
                                />
                            </>
                            }
                        </div>
                        <div>
                            <span>{' '}</span>
                        </div>
                        <div>
                            <Language />
                        </div>
                    </Flex>
                </Col>
            </Row>
            <Confirmation/>
        </Header>
    );
};