import { ProjectThunk } from '../../data/store/project/project-thunk';
import { ProjectGenerateResponse } from '../../data/store/project/response/project-generate-response';
import { EPlatform } from '../../pages/main/e-platform';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../data/links';
import { useAppDispatch } from '../../data/store/store';

export const GenerateVideo = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    navigate: ReturnType<typeof useNavigate>,
    text: string,
    platform?: EPlatform | null,
    projectKey?: string | null
) => {
    const rpv = await dispatch(ProjectThunk.Generate({
        text: text,
        platform: platform,
        projectKey: projectKey
    }));
    const v = rpv.payload as ProjectGenerateResponse;
    if (v.success) {
        if (v.projectKey) {
            navigate(Links.Project.Generate({ projectKey: v.projectKey ?? projectKey, platform: platform }));
        }
    } else {
        navigate(Links.Project.Failed);
    }
};