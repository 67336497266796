import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { SubscriptionThunk } from './subscription-thunk';
import { Subscription } from '../../suscription/subscription';
import { ProjectThunk } from '../project/project-thunk';


export interface SubscriptionSubState {
    loading: boolean,
    subscriptions?: Subscription[],
    videosLastMonth?: number,
    freeUserData?: {
        videosGenerated: number,
        paidIntThePast: boolean
    }
}

export interface SubscriptionState {
    subscriptions?: SubscriptionSubState
}
const initialState: SubscriptionState = {};

const GetSubscriptionsBuilders = (builder: ActionReducerMapBuilder<SubscriptionState>) => {
    builder.addCase(SubscriptionThunk.Get.pending, (state) => {
        state.subscriptions = { loading: true };
    });
    builder.addCase(SubscriptionThunk.Get.fulfilled, (state, { payload }) => {
        if (payload?.success)
            state.subscriptions = {
                loading: false,
                subscriptions: payload?.subscriptions,
                videosLastMonth: payload?.videosLastMonth,
                freeUserData: payload?.freeUserData
            };
        else
            state.subscriptions = {
                loading: false
            };
    });
    builder.addCase(ProjectThunk.Generate.fulfilled, (state) => {
        if (!state.subscriptions)
            return;
        state.subscriptions.videosLastMonth = (state.subscriptions.videosLastMonth ?? 0) + 1;
    });
    builder.addCase(SubscriptionThunk.Get.rejected, (state) => {
        state.subscriptions = { loading: false };
    });
};

const slice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        logout: () => initialState
    },
    extraReducers: (builder) => {
        GetSubscriptionsBuilders(builder);
    },
});

export const SubscriptionSlice = {
    slice: slice.reducer,
    actions: slice.actions,
    whitelist: [] as string[]
};