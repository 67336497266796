import { Button, Form } from 'antd';
import { T, UseLanguageData } from '../../components/elemental/t';
import { Input } from '../../components/elemental/input';
import { t } from 'i18next';
import { FormMiddle } from '../../components/form/form-middle';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { FormItemComplex } from '../../components/form/form-item-complex';
import { A } from '../../components/elemental/a';
import { Links } from '../../data/links';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { LoginErrorEnum, LoginResponse } from '../../data/store/auth/response/login-response';
import { EPlatform } from '../main/e-platform';
import { ParseToEnum } from '../../common/utils/enum-utils';
import { Loading } from '../../components/elemental/loading';
import { GenerateVideo } from '../../common/project/generate';
import { useParameters } from '../../common/utils/use-parameters';


export const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const language = UseLanguageData();

    const [url, platform, emailD] = (() => {
        const [url, platform, emailD] = useParameters(['u', 'p', 'e']);
        return [url, ParseToEnum(EPlatform, platform), emailD];
    })();

    const { user } = useAppSelector((status) => status.Auth);

    const [email, setEmail] = useState<string>(emailD ?? '');
    const [password, setPassword] = useState<string>('');

    const HandleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const HandleChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const HandleSubmit = async () => {
        if (!email || !password)
            return;
        const rp = await dispatch(AuthThunk.Login({
            email: email,
            password: password,
            rememberme: true
        }));
        if ((rp.payload as LoginResponse).success) {
            if (url) {
                navigate(Links.Main.Generate(platform));
                GenerateVideo(dispatch, navigate, url, platform);
                return;
            }
            navigate(Links.Main.Default);
        }
            
    };

    const RenderResendActivation = () => {
        const HandleResend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(AuthThunk.ResendActivation({ email: email, lang: language.language }));
        };

        if (user?.resendActivation?.lastActivationSend != null && ((new Date()).getTime()  - new Date(user.resendActivation.lastActivationSend).getTime()) < 120000)
            return <T k='auth.error.notAct' />;
        return <><T k='auth.error.notAct' />{' '}<A to="#" onClick={HandleResend}><T k='auth.resend.button' /></A></>;
    };
    const RenderErrorCode = (e: LoginErrorEnum) => {
        switch (e) {
            default:
            case LoginErrorEnum.Unknown:
                return <></>;
            case LoginErrorEnum.WrongPasswordOrEmail:
                return <T k='auth.error.wrongUP' />;
                //return <div className="error"><T k='auth.error.wrongUP' /></div>;
            case LoginErrorEnum.NotActivated:
                return RenderResendActivation();
                //return <div className="error"><T k='auth.error.notAct' /></div>;
        }
    };


    return (
        <LayoutMiddle className="login">
            <FormMiddle>
                <Form.Item>
                    <h1><T k='auth.login.title' /></h1>
                    <p><T k='auth.login.subtitle' /></p>
                </Form.Item>
                {user?.login?.loading && <Loading />}
                {!user?.login?.loading && <>
                    <Form.Item
                        label={<T k='auth.login.username.label' />}
                        rules={[{ type: 'email' }]}
                        validateStatus={user?.login?.errorCode ? 'error' : undefined}
                        help={user?.login?.errorCode ? RenderErrorCode(user?.login?.errorCode) : undefined}
                    >
                        <Input key='email' type='email' name='email' placeholder={t('auth.login.username.placeholder')} value={email} onChange={HandleChangeEmail} />
                    </Form.Item>
                    <FormItemComplex label={<T k='auth.login.password.label' />} auxiliar={<A to={Links.Auth.ForgotPassword}><T k='auth.login.password.forgot'/></A>}>
                        <Input key='password' type='password' name='password' value={password} onChange={HandleChangePassword} />
                    </FormItemComplex>
                    <Form.Item>
                        <Button type="primary" onClick={HandleSubmit}><T k='auth.login.submit' /></Button>
                    </Form.Item>
                    <Form.Item>
                        <p><T k="auth.login.noAccount" />{' '}<A to={url != null ? Links.Auth.RegisterCompose({ url: url, platform: platform }) : Links.Auth.Register}><T k="auth.login.createAccount" /></A></p>
                    </Form.Item>
                </>}
            </FormMiddle>
        </LayoutMiddle>
    );
};