import { Button, Form, Input } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { T, UseLanguageData, UseTranslation } from '../../components/elemental/t';
import { useAppDispatch } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { useState } from 'react';
import { Loading } from '../../components/elemental/loading';

export const ForgotPassword = () => {

    const lang = UseLanguageData();
    const t = UseTranslation();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState<string>('');
    const [step, SetStep] = useState<number>(0);

    const HandleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

    const HandleSubmit = async () => {
        SetStep(1);
        try { await dispatch(AuthThunk.SendEmailForgot({ email: email, lang: lang.language })); } catch (ex) { console.log(ex); }//We don't care about errors, payloads, etc.
        SetStep(2);
    };

    return (
        <LayoutMiddle className="login">
            <FormMiddle
                onFinish={HandleSubmit}
            >
                <Form.Item>
                    <h1><T k='auth.forgot.title' /></h1>
                    {step == 0 && <p><T k='auth.forgot.subtitle' /></p>}
                </Form.Item>
                {step == 0 && <>
                    <Form.Item
                        rules={[{ type: 'email' }]}
                    >
                        <Input key='email' type='email' name='email' placeholder={t('auth.login.username.placeholder')} onChange={HandleChangeEmail} value={email} />
                    </Form.Item>
                    <Form.Item className="two-buttons">
                        <Button type="primary" onClick={HandleSubmit}><T k='auth.forgot.submit' /></Button>
                    </Form.Item>
                </>}
                {step == 1 && <Loading/>}
                {step == 2 && <Form.Item><T k='auth.forgot.done' /></Form.Item>}
            </FormMiddle>
        </LayoutMiddle>
    );
};