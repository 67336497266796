import './subscription-card.scss';

import { Button, Card, Divider } from 'antd';
import { Product } from '../../data/payment/product';
import { T, TL } from '../elemental/t';
import { Price } from './price';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { A } from '../elemental/a';
import { Links } from '../../data/links';
import { PaymentThunk } from '../../data/store/payment/payment-thunk';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { CheckoutSessionResponse } from '../../data/store/payment/response/checkout-session-response';
import { loadStripe } from '@stripe/stripe-js';
import { Loading } from '../elemental/loading';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlUtils } from '../../common/utils/url-utils';

interface Props {
    product: Product,
}

export const SubscriptionCard = (props: Props) => {
    const { product } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState<boolean>(false);

    const { user } = useAppSelector(state => state.Auth);

    const OnHire = async () => {
        if (!user?.logged) {
            navigate(Links.Auth.Login);
        }
        setLoading(true);
        const rp = await dispatch(PaymentThunk.CheckoutSession({
            successUrl: UrlUtils.Absolutize(Links.Payment.Success),
            cancelUrl: UrlUtils.Absolutize(Links.Payment.Cancel),
            items: [
                {
                    platformProductId: product.platformProductId,
                    quantity: 1
                }
            ]
        }));
        const stripe = await loadStripe((rp.payload as CheckoutSessionResponse).publicKey);
        await stripe?.redirectToCheckout({ sessionId: (rp.payload as CheckoutSessionResponse).sessionId });
    };

    const free = product.price == 0;
    const ContinueLine = () => {
        if (loading)
            return <div className="button-line"><Loading /></div>;
        if (free)
            return <div className="button-line"><A to="/"><Button type="default"><T k='payments.subscriptions.continue' /></Button></A></div>;
        return <div className="button-line"><Button type="primary" onClick={OnHire}><T k='payments.subscriptions.continue' /></Button></div>;
    };
    const QuantityLine = () => {
        if (free) 
            return <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.quantity1' v={{ q: product.quantity }} /></div>;
        return <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.quantity' v={{ q: product.quantity }} /></div>;
    };
    const PublishLine = () => {
        if (free)
            return <div className="line disabled"><CloseCircleOutlined />{' '}<T k='payments.subscriptions.publishing' /></div>;
        return <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.publishing' /></div>;
    };
    const AiChatLine = () => {
        if (free) {
            return <div className="line disabled"><CloseCircleOutlined />{' '}<T k='payments.subscriptions.aichat' /></div>;
        }
        return <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.aichat' /></div>;
    };

    return <Card className={'subscription-card subscription-card-' + product.style} title={<TL k={'payments.subscriptions.names.' + product.name.toLowerCase()} />}>
        <Price currency={product.currency} value={product.price} billing={product.billing} />
        {ContinueLine()}
        <Divider />
        {QuantityLine()}
        <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.scraping' /></div>
        <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.music' /></div>
        <div className="line"><CheckOutlined />{' '}<T k='payments.subscriptions.voice' /></div>
        {AiChatLine()}
        {PublishLine()}
    </Card>;
};