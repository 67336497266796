import { T } from '../../components/elemental/t';
import { EPlatform } from './e-platform';
import { MainBase } from './main-base';


export const MainTiktok = () => {
    return <MainBase
        titleText={<T k='main.title.tiktok' />}
        className='main-tiktok'
        platform={EPlatform.TikTok}
    />;
};