import { Subscription } from '../../data/suscription/subscription';
import { SubscriptionSubState } from '../../data/store/subscription/subscription-slice';

export const SubscriptionUtils = {
    Quota: (sd: SubscriptionSubState | Subscription[]): number => {
        let subscriptions: Subscription[];
        if (Array.isArray(sd)) {
            subscriptions = sd;
        } else {
            subscriptions = sd.subscriptions ?? [];
        }
        return subscriptions.map(x => x.quantity).reduce((a, b) => a + b, 0) ?? 0;
    },
    IsFree: (sd: SubscriptionSubState | Subscription[]) => SubscriptionUtils.Quota(sd) == 0,
    RemainingQuota: (sd: SubscriptionSubState | Subscription[], videosLastMonth?: number) => {
        const quota = SubscriptionUtils.Quota(sd);
        if (Array.isArray(sd)) {
            return quota - (videosLastMonth ?? 0);
        } else {
            return quota - (sd.videosLastMonth ?? 0);
        }
    }
};