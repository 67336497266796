import { Flex, Form, FormProps } from 'antd';
import { ReactNode } from 'react';

import './form-middle.scss';

interface Props extends FormProps {
    children: ReactNode
}

export const FormMiddle = (props: Props) => {
    const { children, className, layout, ...rest } = props;

    return <Form {...rest} className={`form-middle ${className ?? ''}`} layout={layout ?? 'vertical'} >
        <Flex align="center" justify="center" vertical>
            {children}
        </Flex>
    </Form>;
};