import './project.scss';

import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { ProjectThunk } from '../../data/store/project/project-thunk';
import { useParameters } from '../../common/utils/use-parameters';
import { ProjectChat } from '../../components/videos/chat/project-chat';
import { EVideoStatus } from '../../data/projects/e-video-status';

export const Project = () => {
    const dispatch = useAppDispatch();
    const [key] = useParameters(['k']);

    const project = (() => {
        const { projects } = useAppSelector(x => x.Video);
        return projects?.[key ?? ''];
    })();

    const intervalRef = useRef<number | null>(null);

    useEffect(() => {//FirstLoading
        if (key == null || key == '')
            return;

        dispatch(ProjectThunk.GetData({
            key: key,
            position: 1
        }));

    }, [key]);

    useEffect(() => {
        if (key == null || key == '')
            return;

        intervalRef.current = setInterval(() => {
            if (!project?.exists || !project.history || project.history.length == 0)
                return;

            if (project.history[project.history.length - 1].status < EVideoStatus.Finished) {
                dispatch(ProjectThunk.GetData({
                    key: key,
                    position: 1
                }));
            }
        }, 5000); // Every 5 seconds

        return () => {
            if (intervalRef?.current) {
                window.clearInterval(intervalRef.current);
            }
        };

    }, [key, project ?? '']);


    if (project?.exists != null && !project.exists)
        return <></>;

    if (key == null)
        return <></>;

    return <div className="project">
        {project?.exists && <ProjectChat projectKey={key} history={project.history ?? []} secret={project.secret ?? ''} />}
    </div>;
};