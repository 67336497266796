import { Post } from '../../com/thunk-base';
import { ProjectGenerateRequest } from './request/projectgenerate-request';
import { GetProjectDataRequest } from './request/load-video-resquest';
import { ProjectGenerateResponse } from './response/project-generate-response';
import { ProjectGetDataResponse } from './response/project-get-data-response';
import { GetProjectHistoryRequest } from './request/get-project-history-request';
import { GetProjectHistoryResponse } from './response/get-history-project-response';

const Path = (path: string) => 'Project/' + path;

export const ProjectThunk = {
    Generate: Post<ProjectGenerateResponse, ProjectGenerateRequest>(Path('generate')),
    GetData: Post<ProjectGetDataResponse, GetProjectDataRequest>(Path('getData')),
    GetHistory: Post<GetProjectHistoryResponse, GetProjectHistoryRequest>(Path('GetHistory'))
};