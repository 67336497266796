import './main.scss';
import { MainDefault } from './main-default';
import { MainInstagram } from './main-instagram';
import { MainTiktok } from './main-tiktok';
import { MainYoutube } from './main-youtube';
import { EPlatform } from './e-platform';


interface Props {
    platform: EPlatform
}

export const MainPage = (props: Props) => {
    switch (props.platform) {
        default:
        case EPlatform.Default:
            return <MainDefault />;
        case EPlatform.Instagram:
            return <MainInstagram />;
        case EPlatform.TikTok:
            return <MainTiktok />;
        case EPlatform.Youtube:
            return <MainYoutube />;
    }
};
