import { ReactNode } from 'react';
import { useTranslation as i8trans } from 'react-i18next';
import EN from '../../data/languages/en';

//Thanks to this weird types we can't force those who try to use <T will be forced and will have autocomplete
//to fulfill with data included in EN, so only valid tags
type PathsToStringProps<T, Key extends string = ''> =
    T extends object
    ? { [K in keyof T]: PathsToStringProps<T[K], `${Key}${Key extends '' ? '' : '.'}${K & string}`> }[keyof T]
    : Key;
type ENPaths = PathsToStringProps<typeof EN>;

interface Props {
    k: ENPaths,
    v?: Record<string, string | PrimitiveType | ReactNode>
}

//T and UseTranslation only accept strings from EN
export const T = (props: Props) => {
    const { k, v } = props;
    const { t } = i8trans('translation');
    return <>{t(k, v)}</>;
};

export const UseTranslation = () => {
    const { t } = i8trans('translation');
    const ft = (key: ENPaths, options?: { [key: string]: string | number | undefined }) => t(key, options);
    return ft;
};

export const UseLanguageData = () => {
    const { i18n } = i8trans('translation');
    return i18n;
};

//TL and UseTranslationL accept any string
interface PropsL {
    k: string,
    v?: Record<string, string | PrimitiveType | ReactNode>
}
export const TL = (props: PropsL) => {
    const { k, v } = props;
    const { t } = i8trans('translation');
    return <>{t(k, v)}</>;
};

export const UseTranslationL = () => {
    const { t } = i8trans('translation');
    return t;
};