import './form-item-complex.scss';

import { Flex, Form, FormItemProps } from 'antd';

type Props = FormItemProps & {
    auxiliar: React.ReactNode
};

export const FormItemComplex = (props: Props) => {
    const { label, className, auxiliar, ...rest } = props;
    return <Form.Item {...rest} className={`form-item-complex ${className ?? ''}`} label={
        <Flex justify="space-between">
            <div>{label}</div>
            <div>{auxiliar}</div>
        </Flex>
    } />;
};