import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { ProjectThunk } from './project-thunk';
import { Project } from '../../projects/project';
import { EVideoStatus } from '../../projects/e-video-status';

export type ProjectState = {
    generate?: {
        loading: boolean
    },
    projects?: {
        [key: string]: Project
    }
}

const initialState: ProjectState = {
    generate: {
        loading: false
    },
    projects: {}
};

const GenerateBuilders = (builder: ActionReducerMapBuilder<ProjectState>) => {
    builder.addCase(ProjectThunk.Generate.pending, (state, { meta }) => {
        const key = meta.arg.projectKey;
        if (key == null) {//New project
            state.generate = { loading: true };
            return;
        }
        //Modifiying project
        const text = meta.arg.text;

        state.projects ??= {};
        state.projects[key]?.history.push({
            text: text,
            status: EVideoStatus.Initial
        });
    });
    builder.addCase(ProjectThunk.Generate.fulfilled, (state) => {
        state.generate = { loading: false };
    });
    builder.addCase(ProjectThunk.Generate.rejected, (state) => {
        state.generate = { loading: false };
    });
};

const GetDataBuilders = (builder: ActionReducerMapBuilder<ProjectState>) => {
    builder.addCase(ProjectThunk.GetData.pending, (state, { meta }) => {
        const { key } = meta.arg;

        state.projects ??= {};
        state.projects[key] ??= { loading: true, history: [] };
        state.projects[key].loading = true;
    });
    builder.addCase(ProjectThunk.GetData.fulfilled, (state, { meta, payload }) => {
        const { key, position } = meta.arg;

        state.projects ??= {};//this is just to make happy typescript
        state.projects[key] ??= { loading: false, history: [] }; //this is just to make happy typescript

        if (!payload?.exists) {
            state.projects[key].exists = false;
            return;
        }

        const history = state.projects[key].history;
        state.projects[key] = {
            ...state.projects[key],
            secret: payload.secret,
            loading: false,
            exists: true,
            history: [...history.slice(0, position - 1), ...(payload.lines ?? [])]
        };
    });
    builder.addCase(ProjectThunk.GetData.rejected, (state, { meta }) => {
        const { key } = meta.arg;
        state.projects ??= {};//this is just to make happy typescript
        state.projects[key] ??= { loading: false, history: [] }; //this is just to make happy typescript
        state.projects[key].loading = false;
    });
};

const slice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        logout: () => initialState
    },
    extraReducers: (builder) => {
        GenerateBuilders(builder);
        GetDataBuilders(builder);
    },
});

export const ProjectSlice = {
    slice: slice.reducer,
    actions: slice.actions,
    whitelist: [] as string[]
};