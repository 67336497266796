import EN from './en';

const ES: typeof EN = {
    auth: {
        confirm: {
            ok: 'Cuenta confirmada',
            error: 'Error activando cuenta, prueba mas tarde'
        },
        error: {
            notAct: 'Email no validado',
            wrongUP: 'Usuario o contraseña erróneas'
        },
        forgot: {
            done: 'En menos de 2 minutos deberías recibir un correo. Mira la carpeta de SPAM si no lo ves.',
            title: 'Recuperar contraseña',
            submit: 'Enviar',
            subtitle: 'Escribe tu correo electrónico para enviarte un enlace de recuperación.'
        },
        login: {
            createAccount: 'Crea una cuenta.',
            noAccount: '¿No tienes cuenta?',
            password: {
                label: 'Contraseña',
                forgot: '¿Olvidaste la contraseña?'
            },
            submit: 'Entrar',
            subtitle: 'Nos alegramos de que vuelvas',
            title: 'Iniciar sesión',
            username: {
                label: 'Email',
                placeholder: 'ejemplo@email.com'
            }
        },
        register: {
            continue: 'Continuar',
            complete: 'Registrar',
            password: {
                label: 'Contraseña',
                forgot: '¿Olvidaste la contraseña?',
                error: {
                    PasswordRequiresNonAlphanumeric: 'Debe contener algún caracter especial',
                    PasswordTooShort: 'Debe tener al menos 6 caracteres',
                    PasswordRequiresUpper: 'Debe contener algua mayúscula',
                    PasswordRequiresDigit: 'Debe contener números',
                    PasswordRequiresLower: 'Debe contener alguna minúscula',
                }
            },
            subtitle: 'Rápido y fácil.',
            title: 'Registro',
            username: {
                label: 'Email',
                placeholder: 'ejemplo@email.com'
            }
        },
        resend: {
            button: 'Enviar email de activación de nuevo'
        },
        reset: {
            title: 'Cambiar contraseña',
            subtitle: 'Escoge una nueva contraseña',
            submit: 'Enviar',
            done: 'Contraseña cambiada con éxito'
        }
    },
    chat: {
        dialog: {
            you: 'Tú',
            video: {
                status: {
                    creating: 'Creando video...',
                    composing: 'Componiendo video...',
                    scenes: 'Creando escenas...',
                    procesingIA: 'Procesando por IA...',
                    starting: 'Iniciando...'
                }
            }
        },
        input: {
            placeholder: '¡Envíame un mensaje y editaré el vídeo para ti!'
        }
    },
    header: {
        login: 'Iniciar sesión',
        pricing: 'Precios',
        settings: 'Configuración'
    },
    main: {
        title: {
            default: 'Crea videos automáticamente',
            tiktok: 'Crea videos automáticamente para TikTok',
            instagram: 'Crea videos automáticamente para Instagram',
            youtube: 'Crea videos automáticamente para Youtube'
        },
        form: {
            url: {
                placeholder: 'Escribe la url de tu web',
                error: 'El texto debe ser una dirección web válida. www.ejemplo.com'
            }
        },
        submit: 'Crear video'
    },
    payments: {
        subscriptions: {
            names: {
                free: 'Gratis',
                pro: 'Pro',
                amateur: 'Amateur',
                enterprise: 'Enterprise'
            },
            billing: {
                0: 'Mensual',
                1: 'Bianual',
                2: 'Anual'
            },
            quantity: '{{q}} videos por mes',
            quantity1: '{{q}} video',
            scraping: 'Scraping',
            publishing: 'Publicar (próximamente)',
            music: 'Música de fondo',
            voice: 'Narrador',
            aichat: 'Editor por chat de IA',
            continue: 'Continuar',
            headers: {
                startedOn: 'Empezó en',
                endsOn: '',
                quantity: 'Videos por mes',
                name: 'Nombre',
                price: 'Precio'
            },
            rows: {
                endsOn: 'Termina el {{d}}',
                renewOn: 'Se renueva el {{d}}'
            }
        },
        price: {
            perMonth: '/mes'
        }
    },
    settings: {
        logout: 'Desconectarse'
    },
    video: {
        download: 'Descargar',
        errors: {
            freeUsersMax: 'Los usuarios gratuitos no pueden generar más de un video',
            quotaLimit: 'Alcanzaste tu límite de cuota',
            unknown: 'Error desconocido'
        }
    }
};


export default ES;