import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { SubscriptionThunk } from '../../data/store/subscription/subscription-thunk';
import { Table } from 'antd';
import { Loading } from '../../components/elemental/loading';
import { T, TL, UseLanguageData } from '../../components/elemental/t';
import { DateComp, DateFormat } from '../../components/date/date-comp';
import { Subscription } from '../../data/suscription/subscription';



export const SettingsSubscription = () => {
    const dispatch = useAppDispatch();
    const language = UseLanguageData();
    const { subscriptions } = useAppSelector(status => status.Subscriptions);

    useEffect(() => {
        dispatch(SubscriptionThunk.Get());
    }, []);

    if (!subscriptions || subscriptions.loading) {
        return <Loading />;
    }

    const RenderEndsOn = (endsOn: string, record: Subscription) => {
        const d = DateFormat(endsOn, language.language);
        if (record.cancelled)
            return <T k='payments.subscriptions.rows.endsOn' v={{ d: d }} />;
        return <T k='payments.subscriptions.rows.renewOn' v={{ d: d }} />;
    };

    const columns = [
        {
            title: <T k='payments.subscriptions.headers.name' />,
            dataIndex: 'name',
            key: 'name',
            render: (name: string) => <TL k={'payments.subscriptions.names.' + name.toLowerCase()} />
        },
        {
            title: <T k='payments.subscriptions.headers.startedOn' />,
            dataIndex: 'startedOn',
            key: 'startedOn',
            render: (startedOn: string) => <DateComp date={startedOn}/>
        },
        {
            title: <T k='payments.subscriptions.headers.quantity' />,
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: <T k='payments.subscriptions.headers.price' />,
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: <T k='payments.subscriptions.headers.endsOn' />,
            dataIndex: 'endsOn',
            key: 'endsOn',
            render: RenderEndsOn
        }
    ];

    return <Table dataSource={subscriptions?.subscriptions ?? []} columns={columns} />;
};