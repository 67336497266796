import { T } from '../../components/elemental/t';
import { EPlatform } from './e-platform';
import { MainBase } from './main-base';

export const MainInstagram = () => {
    return <MainBase
        titleText={<T k='main.title.instagram' />}
        className='main-instagram'
        platform={EPlatform.Instagram}
    />;
};