import './content.scss';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { Links } from '../../../data/links';
import { useAppSelector } from '../../../data/store/store';
import { useEffect } from 'react';
import { routes } from '../../../data/routes';

interface Props {
    children: React.ReactNode
}
export const PrivateWrapper = (props: Props) => {
    const navigate = useNavigate();
    const { user } = useAppSelector((status) => status.Auth);

    useEffect(() => {
        if (!user?.logged) {
            navigate(Links.Auth.Login);
        }
    }, [user?.logged ?? false, navigate]);

    if (!user?.logged)
        return <></>;

    return props.children;
};

export interface RouteData {
    path: string,
    element: React.ReactNode,
    private?: boolean
}


export const AppContent = () => {

    const RouteElement = (d: RouteData) => {
        if (d.private)
            return <PrivateWrapper>{d.element}</PrivateWrapper>;
        return d.element;
    };
    const RenderRoute = (d: RouteData, i: number) => <Route key={i} path={d.path} element={RouteElement(d)} />;

    return (
        <Content>
            <Routes>
                {routes.map(RenderRoute)}
            </Routes>
        </Content>
    );
};