import { Button, Form, Input } from 'antd';
import { FormMiddle } from '../../form/form-middle';
import { T, UseTranslation } from '../../elemental/t';
import { useAppDispatch, useAppSelector } from '../../../data/store/store';
import { useNavigate } from 'react-router-dom';
import { UseAuth } from '../../../data/store/auth/auth';
import { EPlatform } from '../../../pages/main/e-platform';
import { Links } from '../../../data/links';
import { GenerateVideo } from '../../../common/project/generate';
import { Loading } from '../../elemental/loading';

interface Props {
    titleText: React.ReactNode,
    platform: EPlatform
}

type FormValue = {
    url: string
}

export const UrlGenerator = (props: Props) => {
    const { titleText, platform } = props;

    const { generate } = useAppSelector(x => x.Video);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = UseAuth();
    const t = UseTranslation();

    const FormSubmit = (e: FormValue) => {

        if (e.url == null || e.url.trim().length == 0)
            return;

        if (!auth.logged) {
            navigate(Links.Auth.LoginCompose({ url: e.url, platform: platform }));
            return;
        }

        GenerateVideo(dispatch, navigate, e.url, platform);
    };

    //const selectAfter = (
    //    <Select defaultValue=".com">
    //        <Select.Option value={ }><T k='main.form.type.url' /></Select.Option>
    //        <Select.Option value={ }><T k='main.form.type.topic' /></Select.Option>
    //        <Select.Option value={ }><T k='main.form.type.text'/></Select.Option>
    //    </Select>
    //);

    return <FormMiddle onFinish={FormSubmit} className="create-video-form" >
        <Form.Item>
            <h1>
                {titleText}
            </h1>
        </Form.Item>
        {generate?.loading && <Form.Item>
            <Loading/>
        </Form.Item>}
        {!generate?.loading && <>
            <Form.Item
                name="url"
                rules={[{
                    pattern: /^(https?:\/\/)?([a-z\d-]*\.)+[a-z]{2,}(:\d+)?/i,
                    message: <T k='main.form.url.error' />
                }]}
            >
                <Input key="url" size="large" placeholder={t('main.form.url.placeholder')} className="input-url" /*addonAfter={selectAfter}*/ />
            </Form.Item>
            <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                    <T k='main.submit' />
                </Button>
            </Form.Item>
        </>}        
    </FormMiddle>;
};