import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../data/store/store';
import { AuthThunk } from '../../../../data/store/auth/auth-thunk';
import { Alert } from 'antd';

export const Confirmation = () => {
    const { user } = useAppSelector((state) => state.Auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        //We don't want to keep it loading it so if it is loaded we won't do it again
        //Neither if it is already being loaded or if it is already confirmed
        if (!user || user?.confirmed?.confirmed || user?.confirmed?.loading || user?.confirmed?.loaded)
            return;

        dispatch(AuthThunk.CheckIfConfirmed());
    }, [user?.confirmed?.confirmed ?? '', user?.confirmed?.loading ?? '']);


    if (!user?.confirmed?.loaded || user?.confirmed?.confirmed)
        return <></>;

    return <Alert message={'a'} />;
};