import { Links } from './links';
import { MainPage } from '../pages/main/main-page';
import { RouteData } from '../components/app/content/content';
import { Login } from '../pages/auth/login';
import { Register } from '../pages/auth/register';
import { Settings } from '../pages/settings/settings';
import { EPlatform } from '../pages/main/e-platform';
import { Project } from '../pages/project/project';
import { ForgotPassword } from '../pages/auth/forgot-password';
import { ResetPassword } from '../pages/auth/reset-password';
import { ConfirmAccount } from '../pages/auth/confirm-account';
import { GatewaySubscriptions } from '../pages/payment/gateway-subscriptions';
import { ProjectFailed } from '../pages/project/project-failed';

const R = (path: string, element: React.ReactNode): RouteData => ({ path, element, private: false }); //Allow anonymus
const P = (path: string, element: React.ReactNode): RouteData => ({ path, element, private: true }); //Only logged users

export const routes: RouteData[] = [
    //Public area
    R(Links.Main.Default,   <MainPage platform={ EPlatform.Default } />),
    R(Links.Main.TikTok,    <MainPage platform={ EPlatform.TikTok } />),
    R(Links.Main.Instagram, <MainPage platform={ EPlatform.Instagram } />),
    R(Links.Main.Youtube,   <MainPage platform={ EPlatform.Youtube } />),
    R(Links.Auth.Login, <Login />),
    R(Links.Auth.Register, <Register />),
    R(Links.Auth.ForgotPassword, <ForgotPassword />),
    R(Links.Auth.ResetPassword, <ResetPassword />),
    R(Links.Auth.ConfirmEmail, <ConfirmAccount />),
    R(Links.Payment.Subscriptions, <GatewaySubscriptions />),

    //Private area
    P(Links.Settings, <Settings />),
    P(Links.Project.DefaultSlug,    <Project />),
    P(Links.Project.InstagramSlug,  <Project />),
    P(Links.Project.TikTokSlug,     <Project />),
    P(Links.Project.YoutubeSlug,    <Project />),
    P(Links.Project.Failed, <ProjectFailed />),
];
