import './main.scss';

import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { EPlatform } from './e-platform';
import { UrlGenerator } from '../../components/videos/generator/url-generator';
import { ProjectHistory } from '../../components/videos/history/project-history';

interface Props {
    titleText: React.ReactNode,
    className?: string,
    platform: EPlatform
}

export const MainBase = (
    props: Props
) => {
    const { titleText, platform } = props;

    return (
        <LayoutMiddle className={`main-page ${props.className ?? ''}`}>
            <UrlGenerator titleText={titleText} platform={platform} />
            <ProjectHistory platform={platform} />
        </LayoutMiddle>
    );
};
