import { Form } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { T } from '../../components/elemental/t';
import { useAppDispatch } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { useEffect, useState } from 'react';
import { useParameters } from '../../common/utils/use-parameters';
import { ConfirmEmailResponse } from '../../data/store/auth/response/confirm-email-response';
import { Loading } from '../../components/elemental/loading';

export const ConfirmAccount = () => {

    const dispatch = useAppDispatch();
    const [userId, code] = useParameters(['userId', 'code']);
    const [step, SetStep] = useState<number>(0);

    if ((userId ?? '') == '' || (code ?? '') == '')
        return <></>;

    useEffect(() => {
        if (code == null || userId == null)
            return;
        (async () => {
            const rp = await dispatch(AuthThunk.ConfirmEmail({ code: code, userId: userId }));
            if ((rp.payload as ConfirmEmailResponse).success) {
                SetStep(2);
            } else {
                SetStep(3);
            }
        })();
    }, [userId, code]);

    if (step == 2) {
        return (
            <LayoutMiddle className="login">
                <FormMiddle>
                    <Form.Item>
                        <p><T k='auth.confirm.ok'/></p>
                    </Form.Item>
                </FormMiddle>
            </LayoutMiddle>
        );
    }

    if (step == 3) {
        return (
            <LayoutMiddle className="login">
                <FormMiddle>
                    <Form.Item>
                        <p><T k='auth.confirm.error' /></p>
                    </Form.Item>
                </FormMiddle>
            </LayoutMiddle>
        );
    }

    return (
        <LayoutMiddle className="login">
            <FormMiddle>
                <Form.Item>
                    <p><Loading/></p>
                </Form.Item>
            </FormMiddle>
        </LayoutMiddle>
    );
};