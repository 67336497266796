import { useLocation } from 'react-router-dom';

export const useParameters = (parameters: string[]) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ret: (string | null)[] = [];
    for (const p  of parameters) {
        ret.push(queryParams.get(p));
    }
    return ret;
};