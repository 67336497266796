import { useNavigate } from 'react-router-dom';
import { A } from '../../components/elemental/a';
import { T } from '../../components/elemental/t';
import { AuthSlice } from '../../data/store/auth/auth-slice';
import { useAppDispatch } from '../../data/store/store';
import { Links } from '../../data/links';

export const SettingsAccount = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const HandleLogout = async () => {
        await dispatch(AuthSlice.actions.logout());
        navigate(Links.Main.Default);
    };

    return <div>
        <A to="#" onClick={HandleLogout} >
            <T k='settings.logout' />
        </A>
    </div>;
};