import './gateway.scss';

import { useEffect, useState } from 'react';
import { Loading } from '../../components/elemental/loading';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { SubscriptionCard } from '../../components/pricing/subscription-card';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { PaymentThunk } from '../../data/store/payment/payment-thunk';
import { ECurrency } from '../../data/payment/currency';
import { Flex, Radio } from 'antd';
import { Product } from '../../data/payment/product';
import { EPaymentPlatform } from '../../data/payment/payment-platform';
import { TL } from '../../components/elemental/t';
import { EBillingPeriod } from '../../data/payment/billing-period';

export const GatewaySubscriptions = () => {
    const { subscriptions } = useAppSelector(status => status.Payment);

    const dispatch = useAppDispatch();
    const [currency, SetCurrency] = useState<ECurrency>(ECurrency.EUR);
    const [billing, SetBilling] = useState<EBillingPeriod>(EBillingPeriod.Yearly);

    useEffect(() => {
        dispatch(PaymentThunk.GetProducts({}));
    }, [PaymentThunk.GetProducts]);

    const RenderContent = () => {
        if (!subscriptions?.products || subscriptions.loading)
            return <Loading />;

        const freeProduct: Product = {
            billing: EBillingPeriod.Unique,
            currency: currency,
            name: 'free',
            platformId:EPaymentPlatform.Stripe,
            price: 0,
            quantity: 1,
            style: 0,
            productId: -1,
            platformProductId: ''
        };

        const subG = subscriptions.products.filter(x => x && x.billing == billing && x.currency == currency);
        const currencies = [...new Set(subscriptions.products.map(x => x.currency))];
        const billings = [...new Set(subscriptions.products.map(x => x.billing))];
        return <>
            <Flex className="options" wrap>
                <Radio.Group optionType="button" className="currencies" value={currency} onChange={(e) => SetCurrency(e.target.value)} options={currencies.map(x => ({ label: ECurrency[x], value: x }))} />
                <Radio.Group optionType="button" className="currencies" value={billing} onChange={(e) => SetBilling(e.target.value)} options={billings.map(x => ({ label: <TL k={'payments.subscriptions.billing.' + x} />, value: x }))} />
            </Flex>
            <Flex className="subscriptions" wrap>
                <SubscriptionCard key={-1} product={freeProduct} />
                {subG.map((p, i) => <SubscriptionCard key={i} product={p} />)}
            </Flex>
        </>;
    };

    return <LayoutMiddle className="gateway">{ RenderContent() }</LayoutMiddle>;
};