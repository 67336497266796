import { UrlUtils } from '../../../common/utils/url-utils';
import { API_URL } from '../../../data/com/client';
import { Links } from '../../../data/links';
import { ProjectHistoryData } from '../../../data/projects/project-history-data';
import { EPlatform } from '../../../pages/main/e-platform';
import { A } from '../../elemental/a';

interface Props {
    project: ProjectHistoryData,
    platform: EPlatform
}
export const ProjectHistoryCard = (props: Props) => {
    const { project, platform } = props;

    const params = UrlUtils.ComposeQueryParams({ k: project.key, p: 1, s: project.secret });
    const src_thumb = API_URL + '/project/thumbnail' + params;
    return <A className="history-card" to={Links.Project.Generate({ projectKey: project.key, platform: platform })}>
        {<img src={src_thumb} />}
    </A>;
};