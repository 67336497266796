import { Post } from '../../com/thunk-base';
import { CheckoutSessionRequest } from './request/checkout-session-request';
import { GetProductsRequest } from './request/get-products-request';
import { CheckoutSessionResponse } from './response/checkout-session-response';
import { GetProductsResponse } from './response/get-products-response';

const Path = (path: string) => 'Payment/' + path;

export const PaymentThunk = {
    GetProducts: Post<GetProductsResponse, GetProductsRequest>(Path('GetProducts')),
    CheckoutSession: Post<CheckoutSessionResponse, CheckoutSessionRequest>(Path('CheckoutSession'))
};