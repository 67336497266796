import { useEffect, useState } from 'react';
import { Loading } from '../../components/elemental/loading';
import { useAppDispatch } from '../../data/store/store';
import { SubscriptionThunk } from '../../data/store/subscription/subscription-thunk';
import { GetSubscriptionResponse } from '../../data/store/subscription/response/get-subscription-response';
import { T } from '../../components/elemental/t';
import { LayoutMiddle } from '../../components/layouts/layout-middle';
import { SubscriptionUtils } from '../../common/subscription/sub-utils';

export const ProjectFailed = () => {
    const dispatch = useAppDispatch();
    const [subR, SetSubR] = useState<GetSubscriptionResponse | null>(null);
    useEffect(() => {
        (async () => {
            SetSubR((await dispatch(SubscriptionThunk.Get())).payload as GetSubscriptionResponse);
        })();
    }, []);

    if (!subR)
        return <Loading />;

    const Error = () => {
        const isFreeUser = SubscriptionUtils.IsFree(subR.subscriptions ?? []);
        if (isFreeUser) {
            if ((subR.freeUserData?.videosGenerated ?? 0) > 0)
                return <T k='video.errors.freeUsersMax' />;
        } else {
            const remainingQuota = SubscriptionUtils.RemainingQuota(subR.subscriptions ?? [], subR.videosLastMonth);
            if (remainingQuota <= 0) {
                return <T k='video.errors.quotaLimit' />;
            }
        }
        return <T k='video.errors.unknown' />;
    };
    return <LayoutMiddle>{Error()}</LayoutMiddle>;
};