import './project-chat.scss';

import { ProjectLine } from '../../../data/projects/project-line';
import { ProjectChatLine } from './project-chat-line';
import { ProjectChatInput } from './project-chat-input';
import { EVideoStatus } from '../../../data/projects/e-video-status';

interface Props {
    history: ProjectLine[],
    projectKey: string,
    secret: string
}

export const ProjectChat = (props: Props) => {
    const { history, projectKey, secret } = props;

    return <div className="project-chat">
        <div className="history">
            {history.map((x, i) => <ProjectChatLine key={i} projectKey={projectKey} position={i + 1} line={x} secret={secret} />)}
        </div>
        <ProjectChatInput disabled={history.find(x => x.status < EVideoStatus.Finished) != null} projectKey={projectKey} />
    </div>;
};