import { Form, Progress } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { Loading } from '../../components/elemental/loading';
import { T } from '../../components/elemental/t';

export const RegisterLoading = () => {
    return <FormMiddle key='f'>
        <Form.Item>
            <h1><T k='auth.register.title' /></h1>
            <p><T k='auth.register.subtitle' /></p>
        </Form.Item>
        <Form.Item>
            <Progress key="p" showInfo={false} percent={33} />
        </Form.Item>
        <Loading />
    </FormMiddle>;
};