import { BaseResponse } from '../../base/base-response';

export enum LoginErrorEnum {
    Unknown = 0,
    WrongPasswordOrEmail = 1,
    NotActivated = 2
}

export type LoginResponse = BaseResponse & {
    token: string;
    errorCode: LoginErrorEnum
};