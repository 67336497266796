import './layout-middle.scss';

import { Flex } from 'antd';

type Props = React.HTMLProps<HTMLDivElement>;

export const LayoutMiddle = (props: Props) => {
    const { children, className, ...rest } = props;
    return <div {...rest} className={`layout-middle ${className ?? ''}`}>
        <Flex align="center" justify="center" vertical >
            {children}
        </Flex>
    </div>;
};