import logo from '../../../../assets/img/logos/logo_header_reversed.png';
import { Links } from '../../../../data/links';
import { A } from '../../../elemental/a';

export const AppLogo = () => {
    return (
        <div className="logo">
            <h1>
                <A to={Links.Main.Default} className="logo">
                    <img src={logo} />
                    <span>VidCreator.AI</span>
                </A>
            </h1>
        </div>
    );
};